import { computed } from 'vue'
import { useRoute } from 'vue-router'
import type { Mission } from '~/types'

export const usePageTitle = () => {
  const route = useRoute()
  const nuxtApp = useNuxtApp()
  
  return computed(() => {
    switch (route.name) {
      case 'index':
        return 'Bienvenue sur WeCoopt'
      case 'missions':
        return 'Liste des missions'
      case 'mission/id': {
        // Récupère la mission depuis le cache de Nuxt
        const missionKey = `mission/${route.params.id}`
        const mission = nuxtApp.payload.data[missionKey] as Mission | undefined
        return mission?.titre || 'Chargement...'
      }
      case 'create-mission':
        return 'Publier une offre'
      case 'candidats':
        return 'Suivi des candidats'
      case 'parametres':
        return 'Paramètres'
      case 'support':
        return 'Support'
      default:
        return 'WeCoopt'
    }
  })
} 