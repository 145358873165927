<template>
  <UHeader class="w-full px-4">
    <template #left>
      <div class="flex items-center gap-4">
        <UButton
          icon="i-heroicons-bars-3"
          color="gray"
          variant="ghost"
          class="lg:hidden"
          @click="handleToggleSidebar"
        />
        <h1 class="text-xl font-semibold">{{ pageTitle }}</h1>
      </div>
    </template>

    <template #right>
      <div class="flex items-center gap-2">
        <span class="text-sm capitalize">{{ colorMode.value }}</span>
        <UColorModeButton variant="solid" />
      </div>
    </template>
  </UHeader>
</template>

<script setup lang="ts">
const pageTitle = usePageTitle()
const colorMode = useColorMode()

const toggleSidebar = inject('toggleSidebar') as () => void

const handleToggleSidebar = () => {
  if (toggleSidebar) {
    toggleSidebar()
  }
}
</script>
