<template>
  <div class="h-full bg-white dark:bg-gray-900 shadow-sm border-r border-gray-200 dark:border-gray-800">
    <UDashboardSidebar class="flex flex-col h-full">
      <template #header>
        <div class="p-4 text-center">
          <img 
          src="../public/images/logo.png" 
          alt="WeCoopt" 
          class="w-32 mx-auto mb-12 dark:brightness-0 dark:invert"
          >
          <UAvatar
            :src="avatarSrc"
            :alt="userName"
            size="xl"
            class="mb-2"
          />
          <h2 class="text-lg font-semibold text-gray-900 dark:text-white">{{ userName }}</h2>
        </div>
      </template>

      <div class="flex-1">
        <UDashboardSidebarLinks 
          :links="navigationLinks"
          :ui="{
            wrapper: 'space-y-1',
            base: 'flex items-center gap-2 p-2 cursor-pointer rounded-lg',
            active: 'text-primary-500 dark:text-primary-400 bg-primary-50 dark:bg-primary-950',
            inactive: 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
          }"
        />
      </div>

      <template #footer>
        <div class="mt-auto">
          <div class="px-4 py-4 border-t border-gray-200 dark:border-gray-800">
            <UButton
              icon="i-heroicons-arrow-left-on-rectangle"
              label="Déconnexion"
              color="gray"
              variant="ghost"
              class="w-full justify-start"
              @click="handleLogout"
            />
          </div>
        </div>
      </template>
    </UDashboardSidebar>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuth } from '~/composables/useAuth'
import { useUser } from '~/composables/useUser'

const navigationLinks = [
  { label: 'Dashboard', icon: 'i-heroicons-squares-2x2', to: '/' },
  { label: 'Missions', icon: 'i-heroicons-briefcase', to: '/missions' },
  { label: 'Publier une offre', icon: 'i-heroicons-plus-circle', to: '/create-mission' },
  { label: 'Suivi des candidats', icon: 'i-heroicons-users', to: '/coming-soon/candidates' },
  { label: 'Paramètres', icon: 'i-heroicons-cog-6-tooth', to: '/coming-soon/settings' },
  { label: 'Support', icon: 'i-heroicons-question-mark-circle', to: '/coming-soon/support' },
]

const router = useRouter()
const { logout } = useAuth()
const { avatarSrc, userName, updateUserInfo } = useUser()

const handleLogout = async () => {
  await logout()
  router.push('/login')
}

onMounted(() => {
  updateUserInfo()
})
</script>
