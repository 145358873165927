<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="225"
      :collapsed="isSidebarCollapsed"
      :class="[
        'z-50',
        isSidebarOpen ? 'block' : 'hidden lg:block'
      ]"
      @close="isSidebarOpen = false"
    >
      <Sidebar />
    </UDashboardPanel>
    <UDashboardContent class="flex-1 w-full overflow-y-auto">
      <Header @toggle-sidebar="toggleSidebar" />
      <slot />
    </UDashboardContent>
  </UDashboardLayout>
</template>

<script setup lang="ts">
const isSidebarOpen = ref(false)
const isSidebarCollapsed = ref(false)

const toggleSidebar = () => {
  if (window.innerWidth < 1024) {
    isSidebarOpen.value = !isSidebarOpen.value
  } else {
    isSidebarCollapsed.value = !isSidebarCollapsed.value
  }
}

// Fermer le sidebar sur mobile quand on change de route
watch(() => useRoute().fullPath, () => {
  if (window.innerWidth < 1024) {
    isSidebarOpen.value = false
  }
})

// Gérer le redimensionnement de la fenêtre
onMounted(() => {
  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      isSidebarOpen.value = true
    } else {
      isSidebarOpen.value = false
    }
  }

  window.addEventListener('resize', handleResize)
  handleResize() // État initial

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize)
  })
})

provide('toggleSidebar', toggleSidebar)
</script>
